body {
  background-color: aqua;
}
#root {
  max-width: 100%;
  font-family: futura, sans-serif;
}
a {
  cursor: pointer;
}
.nav-color {
  background-color: aqua;
}
.nav-text {
  color: black;
  font-family: futura;
}
.nav-text:hover {
  color: aqua;
  background-color: black;
}
#home {
  animation-name: load;
  animation-duration: 0.5s;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: aqua;
  font-family: futura;
}

.home-box {
  border: 0.25rem solid hotpink;
}

#home h6 {
  text-align: center;
  margin-top: 2rem;
}
#home p {
  font-size: 0.9rem;
}
#home a {
  text-decoration: none;
  color: aqua;
  margin: 0 auto;
}
#home a:hover {
  color: hotpink;
}
#album-cover {
  background-image: url(https://i.imgur.com/07If8aZ.jpg?2);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: inline-block;
}
#horizontal-box {
  background-image: url(https://i.imgur.com/OxDOcPe.jpg),
    url(https://i.imgur.com/fvUNrq5.jpg);
  background-repeat: no-repeat, repeat;
  background-size: contain, contain;
  background-position: bottom, top;
  display: inline-block;
}
#vertical-box {
  background-image: url(https://i.imgur.com/pcKV6Fn.jpg);
  background-repeat: repeat;
  background-size: contain;
  background-position: center;
  display: inline-block;
}
#listen {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background-color: black;
  color: aqua;
}
.stream-links {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 auto;
  text-align: center;
}
#gallery {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: aqua;
}
#about {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: black;
  color: aqua;
}
.bio {
  margin: 2.5rem;
  color: hotpink;
  width: 90%;
  font-family: Georgia, "Times New Roman", Times, serif;
}
.button-box {
  display: flex;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  justify-content: space-evenly;
  background-color: aqua;
  color: black;
  width: 100%;
  height: 10%;
}
#about a {
  margin: 0 auto;
}
#yt-logo {
  height: 7rem;
  width: 11rem;
  display: inline;
  max-width: 100%;
  height: auto;
  margin: 5px auto;
}
#twitter-logo {
  height: 7rem;
  width: 7rem;
  display: inline;
  max-width: 100%;
  height: auto;
  margin: 5px auto;
  border-radius: 50%;
}
#insta-logo {
  height: 8rem;
  width: 12rem;
  display: inline;
  max-width: 100%;
  height: auto;
  margin: 5px auto;
}
#itunes-logo {
  height: 6rem;
  width: 8rem;
  display: inline;
  max-width: 100%;
  height: auto;
  margin: 5px auto;
  border-radius: 50%;
}
#amazon-logo {
  height: 9rem;
  width: 8rem;
  display: inline;
  max-width: 100%;
  height: auto;
  margin: 5px auto;
}
#deezer-logo {
  height: 8rem;
  width: 8rem;
  display: inline;
  max-width: 100%;
  height: auto;
  margin: 5px auto;
  border-radius: 50%;
}
#fb-logo {
  height: 6rem;
  width: 8rem;
  display: inline;
  max-width: 100%;
  height: auto;
  margin: 5px auto;
  border-radius: 50%;
}

@keyframes load {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
@media only screen and (max-width: 575px) {
  #home p {
    font-size: 0.8rem;
  }
  #home h5 {
    font-size: 0.8rem;
  }
  #listen h6 {
    font-size: 0.8rem;
  }
  #about h5,
  p {
    font-size: 0.8rem;
  }
}
